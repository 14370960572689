<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <confirmation-dialog
        @close="dialogDelete = false"
        @confirm="deletedLead"
        :dialog="dialogDelete"
        :dialogHeadline="deleteConfirmationHeadline"
      ></confirmation-dialog>
      <v-col cols="12">
        <CreateAndEditMember
          v-if="dialog"
          :selectedMemberData="selectedLead"
          :dialogStatus="dialog"
          @close="dialog = false"
          @refresh="deletedLead"
          ref="createAndEditForm"
        ></CreateAndEditMember>
        <div>
          <v-data-table
            :loading="loading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :headers="headers"
            :items="leads"
            :disable-sort="true"
            class="elevation-0 pb-4 pt-4"
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  @input="searchLeads(1)"
                  append-icon="mdi-magnify"
                  :label="$t('lead.search')"
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-if="manageLead" v-slot:item.actions="{ item }">
              <v-btn
                class="green-color"
                color="green"
                icon
                @click="
                  dialog = true;
                  onSelectLead(item);

                  deletedLeadItem = item;
                "
              >
                <v-icon>mdi-account-plus</v-icon> </v-btn
              >&nbsp;
              <v-btn color="error" class="mr-3 red-color" :ripple="false" icon>
                <v-icon
                  medium
                  @click="
                    deletedLeadItem = item;
                    dialogDelete = true;
                  "
                >
                  mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="leads.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateAndEditMember from "../components/members/CreateAndEditMember";
import { showSnackbar } from "../eventbus/action.js";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { MANAGE_USER, MANAGE_LEAD } from "@/constants/permission";
import _ from "lodash";
export default {
  components: { CreateAndEditMember, ConfirmationDialog },
  data() {
    return {
      deleteConfirmationHeadline: this.$t("lead.deleteHeadline"),
      manageLead: false,
      dialog: false,
      loading: false,
      dialogDelete: false,
      itemsPerPage: 50,
      page: 1,
      search: "",
      headers: [
        {
          text: this.$t("lead.companyName"),
          align: "start",
          value: "company_name",
          width: "25%",
        },
        {
          text: this.$t("lead.representativeName"),
          value: "representative_name",
          width: "25%",
        },
        {
          text: this.$t("lead.representativePhoneNumber"),
          value: "representative_phone_number",
          width: "25%",
        },
        {
          text: this.$t("lead.actions"),
          value: "actions",
          width: "25%",
        },
      ],
      selectedLead: {},
      deletedLeadItem: {},
    };
  },

  computed: {
    ...mapGetters({
      leads: "leads/getLeads",
      meta: "leads/getMeta",
      user: "user/getUser",
    }),
  },
  created() {
    this.initialize(1);
  },

  methods: {
    ...mapActions({
      getLeads: "leads/getLeads",
      deleteLead: "leads/deleteLead",
    }),
    async initialize(page) {
      this.page = page;
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageLead =
        userPermission.includes(MANAGE_LEAD) &&
        userPermission.includes(MANAGE_USER);
      this.loading = true;
      try {
        await this.getLeads({ search: this.search, page: this.page });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    searchLeads: _.debounce(function (page) {
      this.initialize(page);
    }, 500),
    async deletedLead() {
      if (this.deletedLeadItem.id) {
        this.loading = true;
        try {
          await this.deleteLead({
            id: this.deletedLeadItem.id,
          });
          this.initialize(1);
          this.dialogDelete = false;
          this.deletedLeadItem = {};
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.loading = false;
        }
      }
    },
    onSelectLead(item) {
      this.selectedLead = {
        user_detail: {
          company_name: item.company_name,
          representative_name: item.representative_name,
          representative_phone: item.representative_phone_number,
          language: "en",
          timezone: "America/Los_Angeles",
        },
        user_subscription: {},
        services: [
          { id: 10, name: "Posting" },
          { id: 11, name: "Broadcasting" },
        ],
      };
    },
  },
};
</script>
