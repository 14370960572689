<template>
  <v-dialog :value="dialog" max-width="750px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{
          !selectedMember.id ? $t("member.addMember") : $t("member.editMember")
        }}</span>
        <v-spacer></v-spacer>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col :cols="selectedMember.id ? '12' : '6'">
              <v-text-field
                autocomplete="username"
                outlined
                v-model="selectedMember.email"
                :label="$t('member.accessEmail')"
                :placeholder="$t('member.accessEmail')"
                @input="$v.selectedMember.email.$touch()"
                @blur="$v.selectedMember.email.$touch()"
                :error-messages="emailErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" v-if="!selectedMember.id">
              <v-text-field
                autocomplete="new-password"
                outlined
                v-model="selectedMember.password"
                :label="$t('member.password')"
                :placeholder="$t('member.password')"
                @input="$v.selectedMember.password.$touch()"
                @blur="$v.selectedMember.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="selectedMember.user_detail.company_name"
                :label="$t('member.companyName')"
                :placeholder="$t('member.companyName')"
                @input="$v.selectedMember.user_detail.company_name.$touch()"
                @blur="$v.selectedMember.user_detail.company_name.$touch()"
                :error-messages="companyNameErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                maxlength="14"
                v-model="selectedMember.user_detail.company_phone"
                :label="$t('member.companyPhone')"
                :placeholder="$t('member.companyPhone')"
                @input="$v.selectedMember.user_detail.company_phone.$touch()"
                @blur="$v.selectedMember.user_detail.company_phone.$touch()"
                :error-messages="companyPhoneErrors"
                hide-details="auto"
                outlined
                v-to-us-format
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="selectedMember.user_detail.company_email"
                :label="$t('member.companyEmail')"
                :placeholder="$t('member.companyEmail')"
                @input="$v.selectedMember.user_detail.company_email.$touch()"
                @blur="$v.selectedMember.user_detail.company_email.$touch()"
                :error-messages="companyEmailErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="selectedMember.user_detail.company_reviews_link"
                :label="$t('member.companyReviewLink')"
                :placeholder="$t('member.companyReviewLink')"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <google-place
                @onAddressSelect="onAddressSelect"
                :address="selectedMember.user_detail.address"
                label="member.locationAddress"
                validationMsg="member.validations.addressIsRequired"
                :isRequired="true"
              >
              </google-place>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="selectedMember.user_detail.city"
                :label="$t('member.city')"
                :placeholder="$t('member.city')"
                hide-details="auto"
                @input="$v.selectedMember.user_detail.city.$touch()"
                @blur="$v.selectedMember.user_detail.city.$touch()"
                :error-messages="cityErrors"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="selectedMember.user_detail.state"
                :label="$t('member.state')"
                :placeholder="$t('member.state')"
                hide-details="auto"
                @input="$v.selectedMember.user_detail.state.$touch()"
                @blur="$v.selectedMember.user_detail.state.$touch()"
                :error-messages="stateErrors"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                v-model="selectedMember.user_detail.zip_code"
                :label="$t('member.zip')"
                :placeholder="$t('member.zip')"
                hide-details="auto"
                @input="$v.selectedMember.user_detail.zip_code.$touch()"
                @blur="$v.selectedMember.user_detail.zip_code.$touch()"
                :error-messages="zipErrors"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="selectedMember.user_detail.representative_name"
                :label="$t('member.representativeName')"
                :placeholder="$t('member.representativeName')"
                @input="
                  $v.selectedMember.user_detail.representative_name.$touch()
                "
                @blur="
                  $v.selectedMember.user_detail.representative_name.$touch()
                "
                :error-messages="representativeNameErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                maxlength="14"
                v-model="selectedMember.user_detail.representative_phone"
                :label="$t('member.representativePhone')"
                :placeholder="$t('member.representativePhone')"
                @input="
                  $v.selectedMember.user_detail.representative_phone.$touch()
                "
                @blur="
                  $v.selectedMember.user_detail.representative_phone.$touch()
                "
                :error-messages="representativePhoneErrors"
                hide-details="auto"
                outlined
                v-to-us-format
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                outlined
                v-model="selectedMember.user_detail.country"
                :items="contoryList"
                item-text="name"
                item-value="value"
                :label="$t('member.country')"
                hide-details="auto"
                :placeholder="$t('member.country')"
                @input="$v.selectedMember.user_detail.country.$touch()"
                @blur="$v.selectedMember.user_detail.country.$touch()"
                :error-messages="countryErrors"
                return-value
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                outlined
                v-model="selectedMember.user_detail.timezone"
                :items="timezoneList"
                :label="$t('member.timezone')"
                :placeholder="$t('member.timezone')"
                @input="$v.selectedMember.user_detail.timezone.$touch()"
                @blur="$v.selectedMember.user_detail.timezone.$touch()"
                :error-messages="timezoneErrors"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                outlined
                v-model="selectedMember.user_subscription.payment_method_id"
                :items="paymentMethods"
                :label="$t('member.paymentMethod')"
                :placeholder="$t('member.paymentMethod')"
                @input="
                  $v.selectedMember.user_subscription.payment_method_id.$touch()
                "
                @blur="
                  $v.selectedMember.user_subscription.payment_method_id.$touch()
                "
                :error-messages="paymentMethodErrors"
                hide-details="auto"
                item-text="name"
                item-value="id"
                return-value
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
                type="number"
                v-model="selectedMember.user_subscription.amount"
                :label="$t('member.payment')"
                :placeholder="$t('member.payment')"
                @input="$v.selectedMember.user_subscription.amount.$touch()"
                @blur="$v.selectedMember.user_subscription.amount.$touch()"
                :error-messages="paymentErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                v-model="selectedMember.services"
                :items="servicesList"
                item-text="name"
                item-value="id"
                :label="$t('member.services')"
                :placeholder="$t('member.services')"
                @input="$v.selectedMember.services.$touch()"
                @blur="$v.selectedMember.services.$touch()"
                :error-messages="servicesErrors"
                multiple
                chips
                hide-details="auto"
                return-value
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                v-model="selectedMember.roomId"
                :items="roomList"
                item-text="text"
                item-value="id"
                :label="$t('member.roomId')"
                :placeholder="$t('member.roomId')"
                @input="$v.selectedMember.roomId.$touch()"
                @blur="$v.selectedMember.roomId.$touch()"
                :error-messages="roomIdErrors"
                chips
                hide-details="auto"
                return-value
              ></v-select>
            </v-col>

            <v-col cols="3">
              <v-switch
                outlined
                v-model="selectedMember.user_detail.licensed"
                :label="$t('member.licensed')"
                hide-details="auto"
                :placeholder="$t('member.licensed')"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          :loading="saving"
          :disabled="
            ($v.selectedMember.$invalid && $v.selectedMember.$anyDirty) ||
            saving
          "
          @click="save"
        >
          Save
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import { toSimplePhone } from "../../utils/number.utility";
import {
  required,
  email,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import GooglePlace from "../common/GooglePlace.vue";
import { showSnackbar } from "../../eventbus/action.js";
export default {
  name: "CreateAndEditMember",
  components: {
    GooglePlace,
  },
  props: {
    dialogStatus: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedMemberData: {
      required: false,
      type: Object,
      default: () => {
        return { user_detail: {} };
      },
    },
  },
  computed: {
    ...mapGetters({
      servicesList: "members/getMemberServices",
      roomList: "members/getRoomList",
      paymentMethods: "paymentMethod/getPaymentMethods",
    }),

    emailErrors() {
      const errors = [];
      if (!this.$v.selectedMember.email.$dirty) return errors;
      !this.$v.selectedMember.email.email &&
        errors.push(this.$t("member.validations.emailIsInvalid"));
      !this.$v.selectedMember.email.required &&
        errors.push(this.$t("member.validations.emailIsRequired"));
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.selectedMember.password.$dirty) return errors;
      !this.$v.selectedMember.password.requiredIf &&
        errors.push(this.$t("member.validations.passwordIsRequired"));
      !this.$v.selectedMember.password.minLength &&
        errors.push(this.$t("member.validations.passwordMinLength"));
      return errors;
    },

    companyNameErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.company_name.$dirty)
        return errors;
      !this.$v.selectedMember.user_detail.company_name.required &&
        errors.push(this.$t("member.validations.companyNameIsRequired"));
      return errors;
    },

    companyEmailErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.company_email.$dirty)
        return errors;
      !this.$v.selectedMember.user_detail.company_email.email &&
        errors.push(this.$t("member.validations.companyEmailIsInvalid"));
      !this.$v.selectedMember.user_detail.company_email.required &&
        errors.push(this.$t("member.validations.companyEmailIsRequired"));
      return errors;
    },

    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.company_phone.$dirty)
        return errors;
      !this.$v.selectedMember.user_detail.company_phone.required &&
        errors.push(this.$t("member.validations.companyPhoneIsRequired"));
      return errors;
    },

    representativeNameErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.representative_name.$dirty)
        return errors;
      !this.$v.selectedMember.user_detail.representative_name.required &&
        errors.push(this.$t("member.validations.representativeNameIsRequired"));
      return errors;
    },

    paymentMethodErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_subscription.payment_method_id.$dirty)
        return errors;
      !this.$v.selectedMember.user_subscription.payment_method_id.required &&
        errors.push(this.$t("member.validations.paymentMethodIsRequired"));
      return errors;
    },

    paymentErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_subscription.amount.$dirty)
        return errors;
      !this.$v.selectedMember.user_subscription.amount.required &&
        errors.push(this.$t("member.validations.paymentIsRequired"));
      return errors;
    },

    representativePhoneErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.representative_phone.$dirty)
        return errors;
      !this.$v.selectedMember.user_detail.representative_phone.required &&
        errors.push(
          this.$t("member.validations.representativePhoneIsRequired")
        );
      return errors;
    },

    timezoneErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.timezone.$dirty) return errors;
      !this.$v.selectedMember.user_detail.timezone.required &&
        errors.push(this.$t("member.validations.timezoneIsRequired"));
      return errors;
    },

    stateErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.state.$dirty) return errors;
      !this.$v.selectedMember.user_detail.state.required &&
        errors.push(this.$t("member.validations.stateIsRequired"));
      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.city.$dirty) return errors;
      !this.$v.selectedMember.user_detail.city.required &&
        errors.push(this.$t("member.validations.cityIsRequired"));
      return errors;
    },

    zipErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.zip_code.$dirty) return errors;
      !this.$v.selectedMember.user_detail.zip_code.required &&
        errors.push(this.$t("member.validations.zipIsRequired"));
      return errors;
    },

    // languageErrors() {
    //   const errors = [];
    //   if (!this.$v.selectedMember.user_detail.language.$dirty) return errors;
    //   !this.$v.selectedMember.user_detail.language.required &&
    //     errors.push(this.$t("member.validations.languageIsRequired"));
    //   return errors;
    // },

    countryErrors() {
      const errors = [];
      if (!this.$v.selectedMember.user_detail.country.$dirty) return errors;
      !this.$v.selectedMember.user_detail.country.required &&
        errors.push(this.$t("member.validations.countryIsRequired"));
      return errors;
    },

    servicesErrors() {
      const errors = [];
      if (!this.$v.selectedMember.services.$dirty) return errors;
      !this.$v.selectedMember.services.required &&
        errors.push(this.$t("member.validations.servicesIsRequired"));
      return errors;
    },

    roomIdErrors() {
      const errors = [];
      if (!this.$v.selectedMember.roomId.$dirty) return errors;
      !this.$v.selectedMember.roomId.required &&
        errors.push(this.$t("member.validations.channelGroupIsRequired"));
      return errors;
    },

    timezoneList() {
      return moment.tz.zonesForCountry("US")
        .concat(moment.tz.zonesForCountry("MX"))
        .concat(moment.tz.zonesForCountry("EG"));
    },
  },

  created() {
    if (!this.selectedMember.user_subscription) {
      this.selectedMember.user_subscription = {
        payment_method_id: "",
        amount: "",
      };
    }
  },

  async mounted() {
    await this.getMemberServices();
    await this.getRooms();
    await this.getPaymentMethods();
  },

  data() {
    return {
      dialog: this.dialogStatus,
      selectedMember: this.selectedMemberData,
      defaultItem: {
        user_detail: {},
        user_subscription: {},
      },
      saving: false,
      langList: [
        {
          name: "English",
          value: "en",
        },
        {
          name: "Spanish",
          value: "es",
        },
        {
          name: "Arabic",
          value: "ar",
        },
      ],
      contoryList: [
        {
          name: "US",
          value: "us",
        },
        {
          name: "Mexico",
          value: "mx",
        },
        {
          name: "Egypt",
          value: "eg",
        },
        {
          name: "Spain",
          value: "es",
        },
      ],
    };
  },

  validations: {
    selectedMember: {
      email: { required, email },
      password: {
        requiredIf: requiredIf(function () {
          return !this.selectedMember.id;
        }),
        minLength: minLength(8),
      },
      services: { required },
      roomId: { required },
      user_subscription: {
        payment_method_id: { required },
        amount: { required },
      },
      user_detail: {
        representative_phone: { required },
        representative_name: { required },
        company_name: { required },
        company_email: { required, email },
        company_phone: { required },
        address: { required },
        state: { required },
        city: { required },
        zip_code: { required },
        timezone: { required },
        // language: { required },
        country: { required },
      },
    },
  },

  methods: {
    ...mapActions({
      updateMember: "members/updateMember",
      createMember: "members/createMember",
      getMemberServices: "members/getMemberServices",
      getRooms: "members/getRoomList",
      getPaymentMethods: "paymentMethod/getPaymentMethods",
    }),

    close() {
      this.dialog = false;
      this.$emit("close", false);
      this.selectedMember = _.cloneDeep(this.defaultItem);
    },

    onAddressSelect(data) {
      this.selectedMember.user_detail = {
        ...this.selectedMember.user_detail,
        ...data,
      };
      delete this.selectedMember.user_detail.valid;
    },

    async save() {
      this.$v.selectedMember.$touch();
      if (!this.$v.selectedMember.$invalid) {
        try {
          this.saving = true;
          let payload = _.cloneDeep(this.selectedMember);
          payload.user_detail.representative_phone = toSimplePhone(
            payload.user_detail.representative_phone
          );
          payload.user_detail.company_phone = toSimplePhone(
            payload.user_detail.company_phone
          );
          if (_.some(payload.services, _.isObject)) {
            payload.service_ids = [...payload.services.map((item) => item.id)];
          } else {
            payload.service_ids = [...this.selectedMember.services];
          }
          payload.room_id = payload.roomId;
          if (typeof payload.user_detail.licensed === "undefined") {
            payload.user_detail.licensed = false;
          }
          if (
            payload.user_detail.country == "mx" ||
            payload.user_detail.country == "es"
          ) {
            payload.user_detail.language = "es";
          } else if (payload.user_detail.country == "eg") {
            payload.user_detail.language = "ar";
          } else {
            payload.user_detail.language = "en";
          }
          delete payload.services;
          if (payload.id) {
            await this.updateMember({
              ...payload,
            });
          } else {
            await this.createMember({
              ...payload,
            });
          }
          this.$emit("refresh");
          await this.close();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>
